import React, {useEffect, useState} from "react";
import {Routes, Route, useLocation, useNavigate} from "react-router-dom";
import {AppProviders} from '../../context/index';
import MobileDetect from "mobile-detect";
import '../../assets/sass/app.scss';
import Header from "./Header";
import Footer from './Footer';
import Home from "../pages/Home";
import Products from "../pages/products/Products";
import Contacts from "../pages/Contacts";
import About from "../pages/About";
import Login from '../pages/Login';
import SignUp from '../pages/SignUp';
import MyServers from "../pages/MyServers";
import MyAccount  from "../pages/MyAccount";
import Privacy from "../pages/Privacy";
import Terms from "../pages/Terms";
import ErrorComponent from '../pages/ErrorComponent';
import BillingDetails from '../pages/BillingDetails';
import EmailVerification from '../pages/EmailVerification';
import ForgotPassword from "../pages/ForgotPassword";
import LandingPagePasswordReset from "../pages/LandingPagePasswordReset";
import EmailConfirmation from "../pages/EmailConfirmation";
import PasswordChangeLogin from "../pages/ChangePassword";
import Payment from "../pages/Payment";
import ToLoginSignup from "../pages/LoginOrSignup";
import ConfigureServer from "../pages/ConfigureServer";
import CustomQuery from "../pages/CustomQuery";
import ThankYou from "../pages/ThankYou";
import QuerySent from "../pages/QuerySent";
import {getCookie} from "../../config/utils";
import {TOKENS} from "../../config/constants";
import routes from '../../config/routes';

const App = () => {
    let location = useLocation();
    let navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [verifyToken, setVerifyToken] = useState("");
    const [showToast, setShowToast] = useState(false);

    const userAgent = window.navigator.userAgent;
    const md = new MobileDetect(userAgent);
    const [isMobile] = useState(Boolean(md.mobile()));
    const regexPattern = /^\/products\/\d+\/configure$/;

    useEffect(() => {
        let accessToken = getCookie(TOKENS.AT);

        setIsLoggedIn(accessToken.length > 0);
        window.scrollTo({
            top: 0
        }); 
        
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [location]);

    return (
        <AppProviders>
            <div className="App">
                <Header
                    navigate={navigate} 
                    isLoggedIn={isLoggedIn}
                />
                <Routes>
                    <Route path={routes.querySent} element={
                        isLoggedIn ? (
                            <QuerySent/>
                        ) : (
                            <ToLoginSignup/>
                        )}  
                    />
                    <Route path={routes.customServer} 
                        element={
                            isLoggedIn ? (
                                <CustomQuery/>
                            ) : (
                                <ToLoginSignup/>
                        )}          
                    />
                    <Route path={routes.configuredServerPayment} 
                        element={
                            <Payment/>
                        }         
                    />
                    <Route path={routes.home} element={
                        <Home 
                            isLoggedIn={isLoggedIn} 
                            setShowToast={setShowToast} 
                            showToast={showToast}
                        />}    
                    />
                    <Route path={routes.products} element={
                        <Products
                            isLoggedIn={isLoggedIn}
                        />}
                    />
                    <Route path={routes.about} element={
                        <About 
                            isLoggedIn={isLoggedIn}
                        />}
                    />
                    <Route path={routes.contacts} element={
                        <Contacts 
                        />}
                    />
                    <Route path={routes.login} element={
                        !isLoggedIn ? (
                            <Login 
                                navigate={navigate} 
                            />
                        ) : (
                            <MyAccount 
                                navigate={navigate} 
                            />
                        )}    
                    />
                    <Route path={routes.signup} element={
                        !isLoggedIn ? (
                            <SignUp navigate={navigate} />
                        ) : (
                            <ErrorComponent/>
                        )}
                    />
                    <Route path={routes.billingDetails} element={
                        isLoggedIn ? (
                            <BillingDetails 
                                isLoggedIn={isLoggedIn}
                            />
                        ) : (
                            <ToLoginSignup/>
                        )}
                    />
                    <Route path={routes.orderCompleted} element={
                        isLoggedIn ? (
                            <ThankYou 
                                isLoggedIn={isLoggedIn}
                            />
                        ) : (
                            <ToLoginSignup/>
                        )}
                    />
                    <Route path={routes.loginOrSignup} element={
                        isLoggedIn ? (
                            <ErrorComponent/>
                        ) : (
                            <ToLoginSignup/>
                        )}    
                    />
                    <Route path={routes.emailVerification} element={
                        !isLoggedIn ? (
                            <EmailVerification
                                setIsLoggedIn={setIsLoggedIn}
                            />
                        ) : (
                            <ErrorComponent/>
                        )}
                    />
                    <Route path={routes.passwordChangeLogin} element={
                        isLoggedIn ? (
                            <PasswordChangeLogin/>
                        ) : (
                            <ErrorComponent/>
                        )}
                    />
                    <Route path={routes.forgotPassword} element={
                        !isLoggedIn ? (
                            <ForgotPassword 
                                navigate={navigate} 
                            />
                        ) : (
                            <ErrorComponent/>
                        )}   
                    />
                    <Route path={routes.landingPage} element={
                        !isLoggedIn ? (
                            <LandingPagePasswordReset  
                                location={location} 
                                navigate={navigate}
                            />
                        ) : (
                            <ErrorComponent/>
                        )}    
                    />
                    <Route path={routes.emailConfirmation} element={
                        !isLoggedIn ? (
                            <EmailConfirmation 
                                setVerifyToken={setVerifyToken}
                                verifyToken={verifyToken}
                            />
                        ) : (
                            <ErrorComponent/>
                        )}    
                    />
                    <Route path={routes.myAccount} element={
                        isLoggedIn ? (
                            <MyAccount 
                                navigate={navigate} 
                            />
                        ) : (
                            <ErrorComponent/>
                        )}
                    />
                    <Route path={routes.myServers} element={
                        isLoggedIn ? (
                            <MyServers/>
                        ) : (
                            <ErrorComponent/>
                        )}
                    />
                    <Route path={routes.configureServer} element={
                        isLoggedIn ? (
                            <ConfigureServer/>
                        ) : (
                            <ToLoginSignup/>
                        )}
                    />
                    <Route path={routes.privacy} element={<Privacy/>}/>
                    <Route path={routes.termsOfUse} element={<Terms/>}/>
                    <Route path="*" element={<ErrorComponent/>}/>
                </Routes>
                {   
                    !((isMobile && location.pathname === routes.customServer) || 
                    (isMobile && location.pathname === routes.loginOrSignup) || 
                    (isMobile && regexPattern.test(location.pathname))) && (
                    <Footer 
                        isLoggedIn={isLoggedIn}
                    />
                )}
                <style jsx>{`
                    .App {
                        min-height: 100vh; 
                        display: flex;
                        flex-direction: column;
                        overflow-x: hidden;
                    }
                `}</style>
            </div>
        </AppProviders>
    );
}
export default App;