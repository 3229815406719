import {useState, Fragment} from "react";
import {useNavigate} from "react-router-dom";
import classNames from "classnames";
import Media from "react-media";
import {useAppContext} from '../../../context/app-context';
import screensVariables from '../../../assets/sass/screensVariables.scss';
import variables from "../../../assets/sass/variables.scss";
import routes from "../../../config/routes";

const VPSTables = ({products, currency, isMobile}) => {
  
  const [isTooltipVisible, setIsTooltipVisible] = useState([]);
  const [expandedProductType, setExpandedProductType] = useState('');
  const navigate = useNavigate();

  const {setLastClickedProduct} = useAppContext();

  const handleSetExpandedType = e => {
    const {type} = e.currentTarget.dataset;
    setExpandedProductType(prevType => prevType === type ? "" : type);
  }
  const onClickTooltipIcon = e => {
    setIsTooltipVisible(prev => ({
      [e.target.dataset.tooltip]: !prev[e.target.dataset.tooltip]
    }));
  };

  const tableRowClasses = indexGlobal => classNames('table-row', {
    'blue-hover' : indexGlobal === 0,
    'grape-hover' : indexGlobal === 1,
    'pink-hover' :  indexGlobal === 2
  }); 

  const tooltipIconClasses = (color, type) =>  classNames('tooltip-icon', {
    'has-background-paleblue': !isTooltipVisible[type],
    'make-white': isTooltipVisible[type],
    [`has-background-${color}`]: isTooltipVisible[type]
  });

  const redirectTo = async (e) => {
    await setLastClickedProduct(e.target.id)
    navigate(`/products/${e.target.id}/configure`)
  };

  const redirectToCustom = () => {
    navigate(routes.customServer)
  };

  return (
    <section className="VPSTables section">
			<Media            //MOBILE
				query={{maxWidth: variables.tabletMax}}
				defaultMatches={isMobile}
			>
        <Fragment>
          {products.map((product, i) => (
            <div className={`table ${product.products.length > 3 ? 'has-large-p-b' : ''}`} id={product.type.toLowerCase()} key={i}> 
              <div className="is-flex is-justify-content-space-between is-align-items-center is-relative">
                  <div className="is-flex">
                    <img 
                      src={`/images/${product.icon}.svg`} 
                      alt="paper-plane" 
                      width={36} 
                      height={36}
                    />
                    <div className="label-wrapper">
                      <span className={`is-size-3-half has-text-${product.color} luck line-height-1`}>{product.type}</span>
                      <span className="is-size-6 line-height-1 nowrap">{product.title}</span>
                    </div>
                  </div>
                  <svg
                    data-tooltip={product.type}
                    className={tooltipIconClasses(product.color, product.type)}
                    onClick={onClickTooltipIcon}
                    width={48}
                    height={48}
                  >
                    <use xlinkHref="/images/lightbulb.svg#bulb-svg"/>
                  </svg>
                  {isTooltipVisible[product.type] &&
                    <ul className="ul-checkmark">
                      {product.highlights.map((highlight, i) => (
                        <li key={i}>
                          <span className="checkmark"/>
                          {highlight}  
                        </li>
                      ))}
                    </ul>
                  }
              </div>
              <hr className="dashed"/>
              <div className="is-flex">
                <div className="mini-table">
                  <div className="table-header-mobile">
                    <div className="table-data-mobile">
                      <img src="/images/cpu.svg" alt="cpu" width={32} height={32}/>
                      <span className="has-text-sky ml-2">CPU</span> 
                    </div>
                    <div className="table-data-mobile">
                      <img src="/images/ram.svg" alt="ram" width={32} height={32}/>
                      <span className="has-text-sky ml-2">RAM</span> 
                    </div>          
                    <div className="table-data-mobile">
                      <img src="/images/hdd.svg" alt="hdd" width={32} height={32}/>
                      <span className="has-text-sky ml-2">Disk</span> 
                    </div>
                    <div className="table-data-mobile">
                      <img src="/images/ip.svg" alt="ip" width={32} height={32}/>
                      <span className="has-text-sky ml-2">IP</span> 
                    </div>
                    <div className="table-data-mobile">
                      <img src="/images/usd-circle.svg" alt="usd" width={32} height={32}/>
                      <span className="has-text-sky ml-2">Price</span> 
                    </div>
                    <div className="table-data-mobile"/>
                  </div>
                  <div>
                    {product.products.slice(0, expandedProductType === product.type ? product.products.length : 3).map((item, i) => (
                      <div key={i} className="table-row-mobile">
                        <div className="table-data-mobile">{item.cpu}</div>
                        <div className="table-data-mobile">{item.ram}</div>
                        <div className="table-data-mobile">{item.disk}</div>
                        <div className="table-data-mobile">{item.ip}</div>
                        <div className="table-data-mobile">{item.price}</div>
                        <div className="table-data-mobile has-button-inside is-justify-content-flex-end">
                          <button 
                            className={`get-btn has-background-${product.color}`}
                            onClick={redirectTo}
                            id={item.id}
                          >
                            <span id={item.id}>Get</span>
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                  {product.products.length > 3 && (
                    <div 
                      className="view-more"
                      data-type={product.type}
                      onClick={handleSetExpandedType}
                    >
                      <div>
                        {expandedProductType === product.type ?
                          "View less plans"
                          :
                          "View even more plans"
                        }
                      </div>
                      <div className={`arrow ${expandedProductType === product.type ? "is-active" : ""}`}/>
                    </div>
                  )}
               
                </div>
              </div>
            </div>
          ))}
          {!!products.length && (                                           
            <div className="table" id="custom" key={4}> 
              <div className="is-flex is-justify-content-space-between is-align-items-center is-relative">
                  <div className="is-flex">
                    <img 
                      src={`/images/gears.svg`} 
                      alt="paper-plane" 
                      width={36} 
                      height={36}
                    />
                    <div className="label-wrapper">
                      <span className={`is-size-3-half has-text-sky luck line-height-1`}>Custom</span>
                      <span className="is-size-6 line-height-1 nowrap">For flexible needs</span>
                    </div>
                  </div>
                  <svg
                    data-tooltip="custom"
                    className={tooltipIconClasses('sky', 'custom')}
                    onClick={onClickTooltipIcon}
                    width={48}
                    height={48}
                  >
                    <use xlinkHref="/images/lightbulb.svg#bulb-svg"/>
                  </svg>
                  {isTooltipVisible.custom && (
                    <ul className="ul-checkmark">
                      <li>
                        <span className="checkmark"/>
                        Take only what you need
                      </li>
                      <li>
                        <span className="checkmark"/>
                        Tailored to your needs
                      </li>
                      <li>
                        <span className="checkmark"/>
                        Pay-as-you-go
                      </li>
                    </ul>
                  )}
              </div>
              <hr className="dashed"/>
              <div className="mini-table">
                <div className="table-header-mobile">
                  <div className="table-data-mobile">
                    <img src="/images/cpu.svg" alt="cpu" width={32} height={32}/>
                    <div className="has-text-sky ml-2">CPU</div> 
                  </div>
                  <div className="table-data-mobile">
                    <img src="/images/ram.svg" alt="ram" width={32} height={32}/>
                    <div className="has-text-sky ml-2">RAM</div> 
                  </div>          
                  <div className="table-data-mobile">
                    <img src="/images/hdd.svg" alt="hdd" width={32} height={32}/>
                    <div className="has-text-sky ml-2">Disk</div> 
                  </div>
                  <div className="table-data-mobile">
                    <img src="/images/ip.svg" alt="ip" width={32} height={32}/>
                    <div className="has-text-sky ml-2">IP</div> 
                  </div>
                  <div className="table-data-mobile">
                    <img src="/images/usd-circle.svg" alt="usd" width={32} height={32}/>
                    <div className="has-text-sky ml-2">Price</div> 
                  </div>
                  <div className="table-data-mobile"/>
                </div>
                <div className="table-row-mobile">
                  <div className="table-data-mobile">1 to 16 Core</div>
                  <div className="table-data-mobile">1 to 32 GB</div>
                  <div className="table-data-mobile">10 to 200 GB</div>
                  <div className="table-data-mobile">1 or 2 Public</div>
                  <div className="table-data-mobile">from {currency}4.95*</div>
                  <div className="table-data-mobile has-button-inside is-justify-content-flex-end">
                    <button 
                      className={`get-btn has-background-sky`}
                      onClick={redirectToCustom}
                      id={4}
                    >
                      <span id={4}>Get</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="has-text-left">
            *Price starting from. Depending on the selected machine and period.
          </div>
        </Fragment>
			</Media>

			<Media
				query={{minWidth: variables.desktopMin}}
				defaultMatches={!isMobile}
			>
        <Fragment>
          {products.map((product, index) => (
            <div className="table" id={product.type.toLowerCase()} key={index}> 
              <div className="is-flex is-justify-content-space-between is-align-items-center">
                <div className="is-flex">
                  <img src={`/images/${product.icon}.svg`} alt="paper-plane" width={48} height={48}/>
                  <div className="label-wrapper">
                    <span className={`is-size-2 has-text-${product.color} luck line-height-1`}>{product.type}</span>
                    <span className="line-height-1">{product.title}</span>
                  </div>
                </div>
                <ul className="ul-checkmark">
                  {product.highlights.map((highlight, i) => (
                    <li key={i}>
                      <span className="checkmark"/>
                      {highlight}  
                    </li>
                  ))}
                </ul>
              </div>
              <hr className="dashed"/>
              <div className="table-header">
                <div className="table-data">
                  <img src="/images/cpu.svg" alt="cpu" width={32} height={32}/>
                  <span className="has-text-sky ml-2">CPU</span> 
                </div>
                <div className="table-data">
                  <img src="/images/ram.svg" alt="ram" width={32} height={32}/>
                  <span className="has-text-sky ml-2">RAM</span> 
                </div>          
                <div className="table-data">
                  <img src="/images/hdd.svg" alt="hdd" width={32} height={32}/>
                  <span className="has-text-sky ml-2">Disk</span> 
                </div>
                <div className="table-data">
                  <img src="/images/ip.svg" alt="ip" width={32} height={32}/>
                  <span className="has-text-sky ml-2">IP</span> 
                </div>
                <div className="table-data">
                  <img src="/images/usd-circle.svg" alt="CPU" width={32} height={32}/>
                  <span className="has-text-sky ml-2">Price</span> 
                </div>
                <div className="table-data has-button-inside"/>
              </div>
              <div>
                {product.products.slice(0, expandedProductType === product.type ? product.products.length : 3).map((item, i) => (
                  <div key={i} className={tableRowClasses(index)}>
                    <span className="table-data">{item.cpu}</span>
                    <span className="table-data">{item.ram}</span>
                    <span className="table-data">{item.disk}</span>
                    <span className="table-data">{item.ip}</span>
                    <span className="table-data">{item.price}</span>
                    <div className="table-data has-button-inside">
                      <button 
                        className={`get-btn has-background-${product.color}`} 
                        onClick={redirectTo}
                        id={item.id}
                      >
                        <span id={item.id}>Get</span>
                      </button>
                    </div>
                  </div>
                ))}
              </div>
              {product.products.length > 3 && (
                <div 
                  className="view-more"
                  data-type={product.type}
                  onClick={handleSetExpandedType}
                >
                  <div>
                    {expandedProductType === product.type ?
                      "View less plans"
                      :
                      "View even more plans"
                    }
                  </div>
                  <div className={`arrow ${expandedProductType === product.type ? "is-active" : ""}`}/>
                </div>
              )}
            </div>
          ))}
          {!!products.length && (                                           
            <div className="table" id="custom"> 
              <div className="is-flex is-justify-content-space-between is-align-items-center">
                <div className="is-flex">
                  <img src="/images/gears.svg" alt="paper-plane" width={48} height={48}/>
                  <div className="label-wrapper">
                    <span className="is-size-2 has-text-sky luck line-height-1">CUSTOM</span>
                    <span className="line-height-1">For flexible needs</span>
                  </div>
                </div>
                <ul className="ul-checkmark">
                  <li>
                    <span className="checkmark"/>
                    Take only what you need  
                  </li>
                  <li>
                    <span className="checkmark"/>
                    Tailored to your needs
                  </li>
                  <li>
                    <span className="checkmark"/>
                    Pay-as-you-go
                  </li>
                </ul>
              </div>
              <hr className="dashed"/>
              <p className="has-text-left is-regular mt-4 mb-6">
                Looking for a custom solution to best fit your needs? We offer a tailored Machine designed and used as you want. 
                Choose your own parameters and use the machine as much time as you need as short as 1 day. Need an on-the-go Machine? 
                Just create your own and pay-as-you-go!
              </p>
              <div className="table-header">
                <div className="table-data">
                  <img src="/images/cpu.svg" alt="cpu" width={32} height={32}/>
                  <span className="has-text-sky ml-2">CPU</span> 
                </div>
                <div className="table-data">
                  <img src="/images/ram.svg" alt="ram" width={32} height={32}/>
                  <span className="has-text-sky ml-2">RAM</span> 
                </div>          
                <div className="table-data">
                  <img src="/images/hdd.svg" alt="hdd" width={32} height={32}/>
                  <span className="has-text-sky ml-2">Disk</span> 
                </div>
                <div className="table-data">
                  <img src="/images/ip.svg" alt="ip" width={32} height={32}/>
                  <span className="has-text-sky ml-2">IP</span> 
                </div>
                <div className="table-data">
                  <img src="/images/usd-circle.svg" alt="CPU" width={32} height={32}/>
                  <span className="has-text-sky ml-2">Price</span> 
                </div>
                <div className="table-data has-button-inside"/>
              </div>
              <div className='table-row sky-hover'>
                <span className="table-data">from 1 to 16 Core</span>
                <span className="table-data">from 1 to 32 GB</span>
                <span className="table-data">from 10 to 200 GB SSD</span>
                <span className="table-data">1 or 2 Public</span>
                <span className="table-data">from {currency}4.95*</span>
                <div className="table-data has-button-inside">
                  <button 
                    className={`get-btn has-background-sky`} 
                    onClick={redirectToCustom}
                  >
                    <span>Get</span>
                  </button>
                </div>
              </div>
            </div>
          )}
        </Fragment>
			</Media>

      <style jsx global>{`
        .VPSTables {
          text-align: center;
          font-weight: bold;
          background-color: #fff !important;
          padding: 6.25rem 8.75rem !important;
          .view-more {
            display: flex;
            align-items: center;
            margin: 1rem 1rem 0 auto;
            width: fit-content;
            gap: 0.25rem;
            cursor: pointer;
            div {
              white-space: nowrap;
            }
            @media all and (max-width: ${screensVariables.mobileMax}) {
              position: absolute;
              bottom: 1rem;
              left: 50%;
              transform: translateX(-50%);
            }
          }
          .table {
            width: 100%;
            padding: 3rem;
            box-shadow: 0px 0px 30px #0000001A;
            border-radius: 30px;
            .label-wrapper {
              display: flex; 
              flex-direction: column; 
              align-items: flex-start;
              margin-left: 2rem;
            }
            .ul-checkmark {
              display: flex;
              gap: 2rem;
              margin-top: 2rem;
              li {
                display: flex;
                gap: 1rem;
                .checkmark {
                  display: block;
                  background-image: url('/images/checkmark.svg');
                  background-size: cover;
                  background-repeat: no-repeat;
                  width: 1.5rem;
                  height: 1.5rem;
                }
              }
            }
            .dashed {
              border: 1px dashed #A3A3A3;
            }
            .table-header {
              display: flex;
              justify-content: space-between;
              width: 100%;
              .table-data {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                width: calc(100%/6);
                &.has-button-inside {
                  width: 5.5rem;
                }
                &.has-long-button-inside {
                  width: 7rem;
                }
              }
            }
            .table-row {
              display: flex;
              justify-content: space-between;
              width: 100%;
              padding-top: 1.25rem; 
              padding-bottom: 1.25rem;
              border-bottom: 1px solid #D5D5D5; 
              &:last-of-type {
                padding-bottom: 0;
                border-bottom: 0; 
              }
              &.blue-hover {
                &:hover {
                  color: ${variables.blue};
                }
              }
              &.grape-hover {
                &:hover {
                  color: ${variables.grape};
                }
              }
              &.pink-hover {
                &:hover {
                  color: ${variables.pink};
                }
              }
              &.sky-hover {
                &:hover {
                  color: ${variables.sky};
                }
              }
              .table-data {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                width: calc(100%/6);
                &.has-button-inside {
                  width: 5.5rem;
                }
              }
            }
          }
          @media all and (max-width: ${screensVariables.mobileMax}) {
            padding: 3.125rem 1.25rem !important;
            height: 100% !important;
            .table {
              position: relative;
              padding: 3.125rem 1.875rem 0 1.875rem;
              &.has-large-p-b {
                padding-bottom: 2rem;
              }
              .label-wrapper {
                margin-left: 1.25rem;
              }
              .tooltip-icon {
                border-radius: 50%; 
                padding: 0.75rem;
                &.make-white {
                  fill: white;
                }
              }
              .ul-checkmark {
                position: absolute;
                top: -11rem;
                left: 0;
                gap: 0.8125rem;
                margin-top: 2rem;
                flex-direction: column;
                white-space: nowrap;
                padding: 1.25rem;
                box-shadow: 0px 0px 50px #00000026;
                background-color: ${variables.white};
                border-radius: ${variables.radius};
              }
              .dashed {
                margin: 1.5rem 0 0 0;
              }
              .types {
                display: flex;
                flex-direction: column;
                gap: 7rem;
                box-shadow: 10px 0px 10px #0000000B;
                width: 7.4375rem;
                padding-top: 7.4375rem;
                white-space: wrap;
              }
              .mini-table {
                overflow-x: scroll;
                max-width: 100%;
                .table-header-mobile {
                  min-width: fit-content;
                  display: flex;
                  align-items: center;
                  gap: 1rem;
                  padding-top: 1.75rem;
                  .table-data-mobile {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 6rem;
                    min-width: calc(100% / 6);
                  }
                }
                .table-row-mobile {
                  min-width: fit-content;
                  width: fit-content;
                  display: flex;
                  align-items: center;
                  gap: 1rem;
                  border-bottom: 1px dashed #A3A3A3;
                  padding-block: 1.75rem;
                  &:last-child {
                    border: none;
                  }
                  .table-data-mobile {
                    white-space: nowrap;
                    width: 6rem;
                    min-width: calc(100%/6);
                    &.has-button-inside {
                      display: flex;
                    }
                    .btn-arrow {
                      color: white;
                      cursor: pointer;
                      width: 30%;
                      border-radius: 1rem;
                      padding: 0.625rem 1.25rem;
                      white-space: nowrap;
                      transition: all 250ms ease-in-out;
                      &:after {
                        content: " →";
                        transition: all 200ms ease-in-out;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}</style>
    </section>
  )
};

export default VPSTables;